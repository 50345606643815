import { Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"

const FourOFour = ({ location, pageContext }) => (
  <Layout
    title="Sidan finns inte"
    location={location}
    pageContext={pageContext}
    addCrumbs={false}
  >
    <h1>Sidan du söker finns inte</h1>
    <p>
      <Link to="/">Gå till start</Link>
    </p>
  </Layout>
)

export default FourOFour